import { IN_HOUSE_DISTRIBUTION_MODES, CHOWBUS_DISTRIBUTION_MODES } from 'src/consts';
import {
  DISPLAY_COLOR_PREFIX,
  DISCOUNT_EXEMPT_PREFIX,
  MEMBER_PRICE_PREFIX,
  OPEN_PRICE_PREFIX,
  POINT_PRICE_PREFIX,
  PRICE_PREFIX,
  TAX_CODE_PREFIX,
  TAX_EXMPT_PREFIX,
} from 'src/components/MenuManagement/DishForm/const';
import {
  PageBatchUpdateMemberPricing,
  PageBatchUpdatePricing,
  PageBulkDiscountExempt,
  PageBulkTaxExempt,
} from 'src/consts/routes';

export const MENU_ACTIONS_DOM_ID = 'menu-builder-actions';
export const MAX_SELECTION_COUNT = 20;

export const MENU_ACTION_TARGES = {
  MENU: 'menu',
  CATEGORY: 'category',
  PAGE: 'page',
  MEAL: 'meal',
};

export const MENU_ACTIONS = {
  EDIT_MENU: 'editMenu',
  UPDATE_MULTIPLE: 'updateMultiple',
  PRICING: 'pricing',
  MEMBER_PRICE: 'memberPrice',
  DISCOUNT: 'discount',
  TAX_SETTINGS: 'taxSettings',
  SORT: 'sort',
  EDIT_DISH: 'editDish',
  CATEGORY_DETAIL: 'detail',
  ADD_EXISTING_DISH: 'addExistingDish',
  ADD_NEW_DISH: 'addNewDish',
  CHANGE_COLOR: 'changeColor',
  SEARCH_MEALS: 'searchMeals',
};
export const MENU_ACTION_LINK = {
  [MENU_ACTIONS.PRICING]: PageBatchUpdatePricing,
  [MENU_ACTIONS.MEMBER_PRICE]: PageBatchUpdateMemberPricing,
  [MENU_ACTIONS.DISCOUNT]: PageBulkDiscountExempt,
  [MENU_ACTIONS.TAX_SETTINGS]: PageBulkTaxExempt,
};

export const MENU_BUILDER_DISTRIBUTION_MODES = [...IN_HOUSE_DISTRIBUTION_MODES, ...CHOWBUS_DISTRIBUTION_MODES];

export const MEAL_INSTANCE_FIELDS = [
  DISPLAY_COLOR_PREFIX,
  PRICE_PREFIX,
  OPEN_PRICE_PREFIX,
  POINT_PRICE_PREFIX,
  MEMBER_PRICE_PREFIX,
  DISCOUNT_EXEMPT_PREFIX,
  TAX_EXMPT_PREFIX,
  TAX_CODE_PREFIX,
];

export const MENU_ROUTE_QUERY = {
  OPEN_MENU_IDS: 'openMenuIds',
  PATH: 'path',
  OPEN_CHANNEL_IDS: 'openContracts',
  MENU_ID: 'menuId',
};
export const THIRD_PARTY_SOURCE = 'third-party';
export const CATEGORY_NEW = 'new';
export const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const FIELD_MENU_HOURS = 'menu_hours';
export const FIELD_MENU_HOURS_OVERRIDE = 'menu_hour_overrides';
export const TEMP_ID_PREFIX = 'temp_';
export const EDIT_MENU_PATH = [MENU_ACTION_TARGES.MENU, MENU_ACTIONS.EDIT_MENU].join('/');
export const SMART_ORDERING_MENU_CHANNELS_VISIBILITY_KEY = 'channel_visibility';
export const ErrorTypes = {
  START_AT_AFTER_END_AT: 'start_at_after_end_at',
  MENU_HOURS_CONFLICT: 'menu_hours_conflict',
};
